<script setup lang="ts">
defineLayout({
  label: 'Wyndham Cancun Layout',
})

const config = {
  stacked: false,
  logoPosition: 'left', // left or center
  menuPosition: 'center', // right or center
  backgroundClass: 'bg-transparent',
  containerClasses: 'border-b border-white',
  bookNowBackground: 'bg-[#0c7d72]',
  languageSelectorText: 'text-white',
  bookNowText: 'text-white',
  booking: false,
  noSpacer: true,
  scrollTriggerDistance: 100,
  scrollBooking: true,
  scrollClass: 'bg-white',
  textClass: 'text-white',
  backdropBlur: true,
}

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)

const { getCommonLayoutData } = usePlayaLayout()
const {
  resorts,
  brands,
  currentResort,
} = await getCommonLayoutData(isVoixTemplatizer)

provide('accentColor', { value: '#0c7d72' })
provide('currentResort', currentResort.value.data)
provide('resorts', resorts.value.data)
provide('brands', brands.value.data)
</script>

<template>
  <WyndhamLayoutWrapper :config="config">
    <slot />
  </WyndhamLayoutWrapper>
</template>

<style lang="scss">
  @import '~/assets/css/wyndham-cancun.scss';
</style>
